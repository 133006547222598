'use client';
import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { cmsImageUrl } from '../../api/apiControllerCms';
import { Autoplay, Navigation } from 'swiper/modules';

const Markalar = ({ data }) => {
    return (
        <div className="relative">
             <div className="absolute left-0 top-0 h-full w-[100px] bg-gradient-to-r from-white via-white/0 to-transparent z-10"></div>
             <div className="absolute right-0 top-0 h-full w-[100px] bg-gradient-to-l from-white via-white/0 to-transparent z-10"></div>
            <Swiper
            slidesPerView={3}
                modules={[Autoplay, Navigation]}
                // spaceBetween={30}
                loop={true}
                autoplay={{
                    delay: 1, // Continuous autoplay without pauses
                    disableOnInteraction: false,
                }}
                speed={4000} // Slow speed for smooth, continuous scrolling
                breakpoints={{
                    320: {
                        slidesPerView: 2, 
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                }}
                navigation
                className="my-swiper w-full"
            >
                {data?.brands?.map((item, index) => (
                    <SwiperSlide key={index}>
                        <img
                            src={cmsImageUrl + item?.image?.url}
                            className="w-full h-[40px] home-swiper-slide"
                            alt={`Brand ${index}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default Markalar;

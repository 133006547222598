import React, { useState } from 'react';
import AxiosController from '../../api/apiController';
import { formatCurrency } from '../../utils/FormatCurrency';
import { Modal, Tabs, Button } from 'antd';
import PremiumForm from '../premium/PremiumForm';
import ProvisionForm from '../provision/ProvisionForm';

const { TabPane } = Tabs;

const AuctionBidAdd = ({ auctionId, detailCar }) => {
  // const formatCurrency = (value) => {
  //   return new Intl.NumberFormat('tr-TR').format(value) + ' TL';
  // };
  const [isProvision, setIsProvision] = useState(false)
  const [isPremium, setIsPremium] = useState(false)
  const [isModalDetailVisible, setIsModalDetailVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalPremiumFormVisible, setIsModalPremiumFormVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalDetailVisible(true);
  // };

  const handleOk = () => {
    setIsModalDetailVisible(false);
  };

  const handleCancel = () => {
    setIsModalDetailVisible(false);
  };
  const handleBid = async () => {
    try {
      const response = await AxiosController.post('/v1/auction/bid/', {
        auction_id: auctionId,
        amount: detailCar.min_increment,
      });
    } catch (error) {
      console.error('Error placing bid:', error);
    }
  };
  const checkPrivileges = () => {
    setIsModalDetailVisible(true);

  }
  const checkPremForm = () => {
    setIsModalPremiumFormVisible(true)
  }
  const checkProvisionForm = () => {
    setIsModalVisible(true)
  }
  return (
    <>
      {isPremium || isProvision ?
        <button
          className="bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] lg:px-[48px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm flex justify-center items-center"
          onClick={handleBid}
        >
          <span className="text-[#09090B] font-semibold">
            {formatCurrency(detailCar.min_increment)} Arttır
          </span>
        </button>
        :
        <div>
          <button
            onClick={checkPrivileges}
            className="bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] lg:px-[48px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm flex justify-center items-center"
          >
            <span className="text-[#09090B] font-semibold">
              Teklif Verebilmek İçin
            </span>
          </button>

        </div>
      }
      <Modal
        title={<span>Teklif verebilmek için yöntem seçiniz</span>}
        open={isModalDetailVisible}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
        className="custom-modal"
      >
        <Tabs defaultActiveKey="1" className=' px-3 pb-3'>
          <TabPane tab="Premium Üyelik" key="1">
            <button onClick={checkPremForm} className='bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] lg:px-[48px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm flex justify-center items-center'>Premium üye ol</button>
            <PremiumForm setIsModalPremiumFormVisible={setIsModalPremiumFormVisible} isModalPremiumFormVisible={isModalPremiumFormVisible} />
          </TabPane>
          <TabPane tab="Provizyon Yükleme" key="2">
            <button onClick={checkProvisionForm} className='bg-[#FFEB00] py-[12px] text-center lg:py-[6px] px-[12px] lg:px-[48px] lg:h-[56px] rounded-[8px] text-[#09090B] w-full lg:w-auto font-medium text-sm flex justify-center items-center'>Provizyon Yükle</button>
            <ProvisionForm isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
          </TabPane>
        </Tabs>
      </Modal>
    </>

  );
};

export default AuctionBidAdd;

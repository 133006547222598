
import React, { useEffect, useState } from 'react';
import {  message, Divider} from 'antd';
import AxiosController, { imageBaseUrl } from '../../api/apiController';


const FeatureGroupView = ({ selectedCarFeatures=[] }) => {

    const [featureGroups, setFeatureGroups] = useState([]);

    useEffect(() => {
        fetchFeatureGroups();
    }, []);

    const fetchFeatureGroups = async () => {
        try {
            const response = await AxiosController.get('/v1/auction/car/features/');
            setFeatureGroups(response);  // Now includes items
        } catch (error) {
            message.error('Özellik grupları getirilirken bir hata oluştu');
        }
    };

    return (
        <div className='flex flex-col gap-4'>
            {featureGroups?.length > 0 &&
            <Divider orientation="left">Özellik Grupları</Divider>}
            <div className='flex flex-col gap-8'>
                {featureGroups?.map((group, index) => {
                    return <div key={index} className='flex flex-col gap-4'>

                        <div className='flex items-center justify-between'>
                            <div>
                            </div>
                            <div className='flex gap-2.5 items-center'>
                                <img className='h-[24px] w-[24px]' src={imageBaseUrl + group?.img_path} alt="" />
                                <span className=" text-[16px] text-black">
                                    {group?.name}
                                </span>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div className="border border-solid border-dotted w-full h-[1px]">
                        </div>
                        <div className='grid grid-cols-4 gap-8 items-center'>
                            {group?.feature_items?.map((groupItem, subIndex) => {
                                return <div key={subIndex}
                                    className='flex items-center justify-between border-b-2 border-dotted'>
                                    <button type='button' className='flex gap-2.5 my-4 '>
                                        <img
                                            className='h-[24px] w-[24px]'
                                            src={selectedCarFeatures.includes(groupItem?.id) ? '/assets/icons/yes.svg' : '/assets/icons/no.svg'}
                                            alt=""
                                        />
                                        <span className=" text-[16px] text-black">
                                            {groupItem?.name}
                                        </span>
                                    </button>
                                   
                                </div>
                            })}
                        </div>
                       

                    </div>
                })}
            </div>
        </div>
    );
};

export default FeatureGroupView;

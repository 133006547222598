import React, { useState } from 'react'
import { Modal, Form, Input, Button } from "antd";
const ProvisionForm = ({isModalVisible, setIsModalVisible}) => {
    const onFinish = (values) => {
        console.log("Form Values:", values);
        setIsModalVisible(false);
      };
    
  return (
    <div>
        <Modal
        // title="Provizyon Yükle"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)} // Modalı kapatır
        footer={null} // Varsayılan footer'ı gizler
        className=""
      >
        <div className="py-5 px-5">
          <div className="text-lg font-semibold">Provizyon Yükle</div>

          <div className="flex flex-col border rounded-3xl justify-center mb-6 mt-[12px]">
            {/* Üstte bir görsel */}
            <img src="/assets/img/provision-img.png" alt="card-icon" className="" />
            <div className="flex justify-between items-center p-[16px]">
              <div className="flex flex-col">
                <span className="font-semibold text-[18px] text-[#09090B]">1 Provizyon</span>
                <span className="mt-[8px] text-sm font-light text-[#27272A]">1 Adet Provizyon</span>
              </div>
              <img src="/assets/icons/tt-logo.svg" alt="" className="w-[141px] h-[36px]" />
            </div>
          </div>
          {/* Form */}
          <Form layout="vertical" onFinish={onFinish}>
            {/* Kart Sahibi Input */}
            <Form.Item
              label="Kart Sahibi"
              name="cardHolder"
              rules={[{ required: true, message: "Kart sahibi adını giriniz!" }]}
              className="-mt-2.5"
            >
              <Input placeholder="Kart Sahibi" className="border-[#E4E4E7] rounded-[12px] p-[10px]" />
            </Form.Item>

            {/* Kart Numarası Input */}
            <Form.Item
              label="Kart Numarası"
              name="cardNumber"
              rules={[
                { required: true, message: "Kart numarasını giriniz!" },
                { len: 16, message: "Kart numarası 16 haneli olmalıdır!" },
              ]}
              className="-mt-2.5"
            >
              <Input
                placeholder="Kart Numarası"
                maxLength={16}
                className="border-[#E4E4E7] rounded-[12px] p-[10px]"
              />
            </Form.Item>
            {/* Yan Yana Ay/Yıl ve CVC Input */}
            <Form.Item>
              <Input.Group className="flex items-center gap-2.5 -mt-2">
                <Form.Item
                  label="Son Kullanma Tarihi (AY/YIL)"
                  name="expiryDate"
                  rules={[
                    { required: true, message: "Son kullanma tarihini giriniz!" },
                    {
                      pattern: /^(0[1-9]|1[0-2])\/\d{2}$/,
                      message: "Geçerli bir tarih giriniz! (MM/YY)",
                    },
                  ]}
                  style={{ width: "50%" }}
                >
                  <Input
                    placeholder="MM/YY"
                    maxLength={5}
                    type="number"
                    className="border-[#E4E4E7] rounded-[12px] p-[10px]"
                  />
                </Form.Item>
                {/* CVC Kodu */}
                <Form.Item
                  label="CVC"
                  name="cvc"
                  rules={[
                    { required: true, message: "CVC kodunu giriniz!" },
                    { len: 3, message: "CVC kodu 3 haneli olmalıdır!" },
                  ]}
                  style={{ width: "50%" }}
                >
                  <Input
                    placeholder="CVC"
                    maxLength={3}
                    className="border-[#E4E4E7] rounded-[12px] p-[10px]"
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            {/* Ödemeyi Yap Butonu */}
            <div className="flex items-center gap-2.5">
              <div className="flex flex-col items-center w-[50%] -mt-2">
                <span className="text-sm text-[#27272A]">TOPLAM TUTAR</span>
                <span className="text-[#09090B] text-[20px] font-bold">15.000 TL</span>
              </div>
              <Form.Item className="w-[50%]">
                <Button
                  htmlType="submit"
                  className="w-full bg-[#FFEB00] border-none h-[56px] rounded-[12px] py-[22px] px-[64px] mt-[8px] text-sm font-semibold hover:bg-[#fff] hover:text-[#FFEB00]"
                >
                  Ödemeyi Yap
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default ProvisionForm
import React, { useState } from "react";
import PremiumForm from "../../components/premium/PremiumForm";
const PremiumTab = () => {
  const [isModalPremiumFormVisible, setIsModalPremiumFormVisible] = useState(false)
  const handleFormModal = () => {
    setIsModalPremiumFormVisible(true)
  }
  return (
    <div className="flex flex-col mt-5 lg:mt-0">
      <div className="bg-[#FFEB00] flex flex-col lg:flex-row items-center rounded-[16px] h-[228px] p-[17px] lg:p-[17px] lg:gap-x-[18px]">
        <div>
          <img src="/assets/img/blog-banner-car.png" alt="banner-car" />
        </div>
        <div className="flex flex-col lg:ml-[32px]">
          <h6 className="font-medium text-sm max-w-sm lg:max-w-auto lg:text-[24px] lg:leading-8 text-[09090B] lg:w-[434px]">
            Premium ile Provizyon Ödemeden Sınırsız Teklif Verebilirsiniz
          </h6>
          <span className="text-[09090B] text-sm lg:w-[434px] hidden lg:block">
            İhalenin Taksitli Hali ttihale.com
          </span>
        </div>
        <button onClick={handleFormModal} className="self-end bg-[#09090B] rounded-[56px] -mt-3 lg:-mt-0 lg:ml-28 text-[#FFEB00] text-sm font-semibold px-[12px] lg:px-[24px] py-3">
          Premium Üye Ol
        </button>
      </div>
      <PremiumForm isModalPremiumFormVisible={isModalPremiumFormVisible} setIsModalPremiumFormVisible={setIsModalPremiumFormVisible} />
      <div className="grid grid-cols-1 lg:grid-cols-3 mt-[48px] gap-y-[12px] lg:gap-y-[48px]">
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/car-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Sınırsız Teklif Hakkı</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/valor-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Valör ile Ödeme Esnekliği</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/support-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Özel Satış Temsilcisi Atama</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/card1-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Güvenilir Hızlı Alım Satım</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/star-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Premium’a Özel İhaleler</span>
        </div>
        <div className="flex items-center gap-2.5">
          <img src="/assets/icons/headset-bordered-yellow.svg" alt="car-svg" />
          <span className="text-[#09090B] font-semibold">Özel Çağrı Merkezi</span>
        </div>
      </div>
      <div className="mt-[40px]">
        <div className="flex flex-col gap-y-[16px]">
          <div className="flex items-center gap-2.5">
            <img src="/assets/icons/info-icon.svg" alt="info-icon" />
            <span className="text-sm font-light text-[#09090B]">
              Premium ile daha fazla keşfedin.
            </span>
          </div>
          <div className="flex items-center gap-2.5">
            <img src="/assets/icons/info-icon.svg" alt="info-icon" />
            <span className="text-sm font-light text-[#09090B]">
              Provizyonlar gün içerisinde satın alma olmadığı takdirde iade edilmez.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumTab;
